import * as React from "react";
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews";

// Context
import { StoreProvider } from "./src/components/context/store-context";

// Prismic
import { linkResolver } from "./src/components/link-resolver/linkResolver";

// Templates
import PageTemplate from "./src/templates/page";
import Product from "./src/templates/product";
import TextPage from "./src/templates/text-page";

// Add Klaviyo script to all pages
export const onClientEntry = () => {
  if (typeof window !== "undefined") {
    // Initialize Klaviyo onsite array
    window._klOnsite = window._klOnsite || [];

    // Function to check if we should show the form
    const shouldShowForm = () => {
      const lastClosed = localStorage.getItem("klaviyo_form_closed");
      const lastSignup = localStorage.getItem("klaviyo_form_signup");

      // If user has never closed or signed up, show the form
      if (!lastClosed && !lastSignup) return true;

      // Calculate days since last interaction
      const now = Date.now();
      const daysSinceClosed = lastClosed
        ? (now - parseInt(lastClosed)) / (1000 * 60 * 60 * 24)
        : 0;
      const daysSinceSignup = lastSignup
        ? (now - parseInt(lastSignup)) / (1000 * 60 * 60 * 24)
        : 0;

      // Don't show if within cooling period for either close or signup
      return daysSinceClosed >= 15 && daysSinceSignup >= 15;
    };

    // Create script element
    const script = document.createElement("script");
    script.id = "klaviyo-script";
    script.type = "text/javascript";
    script.async = true;
    script.src = "https://static.klaviyo.com/onsite/js/VnifWV/klaviyo.js";

    // Add script to document (in head for better loading)
    document.head.appendChild(script);

    // Initialize tracking once script is loaded
    script.onload = () => {
      console.log("Klaviyo script loaded");

      // Only initialize the form if we should show it
      if (shouldShowForm()) {
        window._klOnsite = window._klOnsite || [];
        window._klOnsite.push(["openForm", "T9Mkzb"]);
        console.log("Klaviyo form initialized with ID: T9Mkzb");
      } else {
        console.log("Klaviyo form not shown - within cooling period");
      }

      // Listen for form events using the klaviyoForms custom event
      window.addEventListener("klaviyoForms", function (e) {
        if (e.detail.type === "close") {
          localStorage.setItem("klaviyo_form_closed", Date.now().toString());
          console.log("Form closed - will not show again for 15 days");
        }
        if (e.detail.type === "submit") {
          localStorage.setItem("klaviyo_form_signup", Date.now().toString());
          console.log(
            "Form submitted successfully - will not show again for 15 days"
          );
        }
      });
    };

    script.onerror = (error) => {
      console.error("Error loading Klaviyo script:", error);
    };
  }
};

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
        linkResolver,
        componentResolver: componentResolverFromMap({
          page: PageTemplate,
          product: Product,
          text_page: TextPage,
        }),
      },
    ]}
  >
    <StoreProvider>{element}</StoreProvider>
  </PrismicPreviewProvider>
);
