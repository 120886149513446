import React from "react";
import styled from "styled-components";

// Hooks
import { useMediaQuery } from "../hooks/useMediaQuery";

// Components
import { Header } from "./header";
import { Footer } from "./footer";

const Main = styled.main`
  flex: 1;
`;

export const App = ({ location, data, children }) => {
  // Media Query
  let isDesktop = useMediaQuery("(min-width: 769px)");

  return (
    <>
      <Header location={location} isDesktop={isDesktop} />
      <Main className={isDesktop ? `desktop` : `mobile`}>{children}</Main>
      <Footer location={location} isDesktop={isDesktop} />
    </>
  );
};
